import { ChatFooterInterface, TabMenuEnum } from './chat-footer.interface';
import Summary from './summary';
import { Tabs } from '@squantumengine/horizon';
import SendChat from './send-chat';
import { useChatListStore } from '../../../../store/chat-list';
import { ConversationStatusEnum } from '../../../../static/message-event';
import { useEffect, useState } from 'react';

const ChatFooterV2 = ({ chat, addChat, handleChange, onTabChange }: ChatFooterInterface) => {
  const { selectedCall } = useChatListStore();
  const [selectedTab, setSelectedTab] = useState<string>(TabMenuEnum.SEND_CHAT);

  const isPreClosure = selectedCall?.conversationStatus === ConversationStatusEnum.PRE_CLOSURE;
  const isResolved = selectedCall?.conversationStatus === ConversationStatusEnum.RESOLVED;
  const isAllowTxtMsg = selectedCall?.inboxConfig?.allowTextMessage;

  const ChatFooterMenus = [
    {
      children: <SendChat addChat={addChat} chat={chat} handleChange={handleChange} />,
      key: TabMenuEnum.SEND_CHAT,
      label: 'Kirim Pesan',
      disabled: isPreClosure || isResolved || !isAllowTxtMsg
    },
    {
      children: <Summary />,
      key: TabMenuEnum.SEND_SUMMARY,
      label: 'Isi Ringkasan'
    }
  ];

  useEffect(() => {
    if (isPreClosure || !isAllowTxtMsg) {
      setSelectedTab(TabMenuEnum.SEND_SUMMARY);
      if (onTabChange) onTabChange(TabMenuEnum.SEND_SUMMARY);
    }
  }, [selectedCall?.conversationStatus, isAllowTxtMsg]);

  return (
    <div className="min-h-28 bg-white py-2">
      <Tabs
        tabListClassName="border-solid px-4 border-0 border-y border-slate-300 mb-0"
        items={ChatFooterMenus}
        size="sm"
        defaultActiveKey={selectedTab}
        onChange={(key) => {
          if (onTabChange) onTabChange(key);
        }}
      />
    </div>
  );
};

export default ChatFooterV2;
